<template>
  <div class="page">
    <template v-if="registrationDisabled">
      <v-alert type="warning" text>
        {{ $t("register.disabled") }}
      </v-alert>

      <!-- Login link -->
      <small class="d-block text-center">
        <router-link :to="{ name: 'auth.login' }">
          {{ $t("login.login") }}
        </router-link>
      </small>
    </template>

    <template v-else>
      <RegisterForm
        v-if="view === 'form'"
        :formData="formData"
        @success="view = 'success'"
      />

      <RegisterSuccess v-if="view === 'success'" :email="formData.email" />
    </template>
  </div>
</template>

<script>
import RegisterForm from "@/components/auth/register/RegisterForm.vue";
import RegisterSuccess from "@/components/auth/register/RegisterSuccess.vue";

export default {
  metaInfo: {
    title: "Registration",
  },

  components: { RegisterForm, RegisterSuccess },

  data: () => ({
    formData: {},
    view: "form",
  }),

  computed: {
    registrationDisabled() {
      if (Number(this.$utils.env("REGISTER_DISABLE"))) {
        const date = this.$utils.dayjs().format("YYYY-MM-DD");
        const code = this.hash(date).toString();
        const queryCode = this.$route.query.code;

        if (queryCode === code) {
          return false;
        }

        return true;
      }

      return false;
    },
  },

  methods: {
    hash(str, seed = 0) {
      let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
      for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
      }
      h1 =
        Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
        Math.imul(h2 ^ (h2 >>> 13), 3266489909);
      h2 =
        Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
        Math.imul(h1 ^ (h1 >>> 13), 3266489909);
      return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    },
  },
};
</script>
