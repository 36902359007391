<template>
  <div class="card">
    <div class="text-center">
      <h1>{{ $t("register.title") }}</h1>
      <div class="subtitle-1">{{ $t("register.subtitle") }}</div>
    </div>

    <v-alert v-if="$route.query['NoEmailError']" class="mt-4" type="error">
      Social registration failed, email not detected.
    </v-alert>

    <v-form
      v-model="formValid"
      @submit.prevent="submit"
      class="mt-13 text-right"
      autocomplete="off"
    >
      <div>
        <!-- Name -->
        <!-- <v-row> -->
        <!-- First name -->
        <!-- <v-col>
            <v-text-field
              v-model="formData.first_name"
              :label="$t('common.firstName')"
              :rules="[$rules.required]"
              :maxlength="$config.profile.maxNameLength"
              filled
              prepend-inner-icon="mdi-account"
            />
          </v-col> -->

        <!-- Last name -->
        <!-- <v-col>
            <v-text-field
              v-model="formData.last_name"
              :label="$t('common.lastName')"
              :maxlength="$config.profile.maxNameLength"
              filled
              prepend-inner-icon="mdi-account"
            />
          </v-col>
        </v-row> -->

        <!-- Email input -->
        <v-text-field
          v-model="formData.email"
          :label="$t('common.email')"
          type="email"
          :rules="[$rules.required, $rules.email]"
          filled
          prepend-inner-icon="mdi-email"
        />

        <!-- Password input -->
        <password-input
          class="mt-10"
          v-model="formData.password"
          :label="$t('login.password')"
          type="password"
          autocomplete="new-password"
          :rules="[$rules.required, $rules.password]"
          filled
          prepend-inner-icon="mdi-key"
        />

        <!-- Checkbox input -->
        <v-checkbox
          class="terms-checkbox mt-0"
          :label="$t('register.termsAndConditions')"
          dense
          :rules="[$rules.required]"
        />

        <!-- Password confirmation input -->
        <!-- <password-input
          v-model="formData.password_confirmation"
          :label="$t('login.passwordConfirm')"
          type="password"
          :rules="[$rules.required, $rules.password]"
          filled
          rounded
          prepend-inner-icon="mdi-key"
        /> -->

        <!-- OR divider -->
        <!-- <v-row justify="center" align="center" class="mb-5 mt-2">
        <v-col><v-divider /></v-col>
        <v-col cols="auto" class="text-h6">
          {{ $t("common.or").toUpperCase() }}
        </v-col>
        <v-col><v-divider /></v-col>
      </v-row> -->

        <!-- Google logregisterin -->
        <!-- <v-btn color="primary" block :href="getSocialLink('google')">
        <v-icon left>mdi-google</v-icon>
        {{ $t("register.registerGoogle") }}
      </v-btn> -->

        <!-- Facebook register -->
        <!-- <v-btn
        color="primary"
        block
        class="mt-4 mb-10"
        :href="getSocialLink('facebook')"
      >
        <v-icon left>mdi-facebook</v-icon>
        {{ $t("register.registerFacebook") }}
      </v-btn> -->
      </div>
      <v-spacer />

      <!-- ApiResponse -->
      <api-response :response="formResponse" class="mt-5" />
      <div>
        <!-- Next button -->
        <v-btn
          color="primary"
          type="submit"
          :disabled="!formValid"
          :loading="$loading('auth/register')"
          block
        >
          {{ $t("common.next") }}
        </v-btn>

        <!-- Login link -->
        <small class="d-block text-center mt-3">
          {{ $t("register.haveAccount") }}
          <router-link :to="{ name: 'auth.login' }">
            {{ $t("login.login") }}
          </router-link>
        </small>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    formData: Object,
  },

  data: () => ({
    formValid: false,
    formResponse: null,
  }),

  methods: {
    submit() {
      if (this.$route.query.referral_code) {
        this.formData.registration_refferal_code =
          this.$route.query.referral_code;
      }

      this.formResponse = null;
      this.$store
        .dispatch("auth/register", this.formData)
        .then((data) => {
          this.$emit("success", data);
        })
        .catch((data) => {
          this.formResponse = data;
        });
    },

    getSocialLink(provider) {
      return this.$utils.env("API_URL") + `auth/${provider}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-checkbox {
  :deep {
    .v-label {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px !important;
    }
  }
}
</style>
